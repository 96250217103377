import makeView from '@tmap/mmm-core/redux/views/makeView';
import client from '../client';
import { RECEIVE_CONTENT } from '../reducers/content';

export const orgSiteSettingsQuery = () => `{
        "settings": *[_id == "orgSiteSettings"][0]
    }`;

export function queryContent(query) {
  return async (dispatch) => {
    const result = await client.fetch(query);
    dispatch({
      type: RECEIVE_CONTENT,
      content: [result.settings]
    });
  }
}

export const getCommunityCurrentSlug = makeView(
  async (communityId) => {
    const query = `{"wwwCommunity": *[_type == "community" && communityId == "${communityId
      }"][0]{_id,_type,communityId,locationText,regionText,"currentSlug":slug.current}}`;
    const res = await client.fetch(query);
    const communityFound = res.wwwCommunity;
    return { docs: communityFound };
  }
);