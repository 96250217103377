import React from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import imageUrlBuilder from '@sanity/image-url';
import client from '../client';

const builder = imageUrlBuilder(client);

const [StyledPicture, StyledImage] = ['picture', 'img'].map((component) => (
  styled(component)(() => ({
    width: '100%',
  }))
));

const MainImageWrapper = styled(Grid)(({ theme }) => ({
  lineHeight: 0,
  maxHeight: '100vh',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    order: -1,
  },
}));

const MainCopyWrapper = styled(Grid, {
  shouldForwardProp: (p) => p !== 'paddingTop',
})(({ theme, paddingTop = '64px' }) => ({
  margin: 'auto',
  paddingTop,
  paddingLeft: '9%',
  paddingRight: '9%',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(10),
  },
}));

function SplitFifty(props) {
  const {
    imageRight = true,
    mainImage,
    paddingTop,
    children,
  } = props;

  const imageGridItem = (
    <MainImageWrapper item xs={12} sm={4} md={6}>
      <StyledPicture>
        <source srcSet={builder.image(mainImage).format('webp').width(732).height(1020).url()} media='(min-width: 960px)' />
        <source srcSet={builder.image(mainImage).format('webp').width(320).height(1020).url()} media='(min-width: 600px)' />
        <source srcSet={builder.image(mainImage).format('webp').width(450).height(325).url()} />
        <StyledImage
          src={builder.image(mainImage).width(1440).height(723).url()}
          alt={mainImage.alt}
        />
      </StyledPicture>
    </MainImageWrapper>
  );

  const bodyGridItem = (
    <MainCopyWrapper item xs={12} sm={8} md={6} paddingTop={paddingTop}>
      {children}
    </MainCopyWrapper>
  );

  return (
    <Grid container>
      {imageRight ? bodyGridItem : imageGridItem}
      {imageRight ? imageGridItem : bodyGridItem}
    </Grid>
  );
}

export default SplitFifty;
