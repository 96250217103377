import React from 'react';
import { useRouter } from 'next/router';
import imageUrlBuilder from '@sanity/image-url';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { ArrowBack } from '@tmap/mmm-style-guide/src/Icon';
import client from '../client';
import FullWrapper from '../layouts/fullWrapper';
import SplitFifty from '../layouts/splitFifty';
import Metatags from '../components/metatags';
import useSiteSettings from '../hooks/useSiteSettings';

const builder = imageUrlBuilder(client);

function NotFound() {
  const doc = useSiteSettings();
  const router = useRouter();

  if (!doc) return null;
  return (
    <>
      <Metatags
        title='404 Not Found'
        description={doc.notFoundTitle}
        canonical={`${process.env.NEXT_PUBLIC_CANONICAL_DOMAIN}/404`}
        image={builder.image(doc.notFoundImage).url()}
      />
      <FullWrapper headerColor='default' breadcrumbs={['Page Not Found']}>
        <SplitFifty mainImage={doc.notFoundImage}>
          <Typography variant='h5' color='grey.400' fontWeight='700'>Error 404</Typography>
          <Typography variant='h3' component='h1' gutterBottom paddingTop={3} fontWeight='700'>{doc.notFoundTitle}</Typography>
          <Typography variant='body1' paddingBottom={7}>{doc.notFoundBody}</Typography>
          <Button variant='contained' color='convert' onClick={() => router.back()}><ArrowBack />&nbsp;&nbsp;Go Back</Button>
        </SplitFifty>
      </FullWrapper>
    </>
  );
}

export default NotFound;
