import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { orgSiteSettingsQuery, queryContent } from '../actions/content';

const useSiteSettings = () => {
  const orgSiteSettings = useSelector((state) => (
    state?.contentStore?.contentTypes?.orgSiteSettings?.orgSiteSettings
  ), shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!orgSiteSettings) {
      dispatch(queryContent(orgSiteSettingsQuery()));
    }
  }, [dispatch, orgSiteSettings]);

  return orgSiteSettings;
};

export default useSiteSettings;
